import { DISCOUNT_TYPE, SUBSCRIPTION_INTERVAL } from './shared';

const mapStripeDiscount = (stripeDiscount) => {
  const { coupon: stripeCoupon } = stripeDiscount;
  const coupon = {
    id: stripeCoupon.id,
    couponType: stripeCoupon.percent_off ? DISCOUNT_TYPE.PERCENT : DISCOUNT_TYPE.AMOUNT,
    couponAmount: stripeCoupon.percent_off || stripeCoupon.amount_off,
  };
  if (stripeCoupon.duration === 'forever') {
    coupon.couponDuration = null;
  } else if (stripeCoupon.duration === 'once') {
    coupon.couponDuration = 1;
  } else if (stripeCoupon.duration === 'repeating') {
    coupon.couponDuration = stripeCoupon.duration_in_months;
  }
  const discount = {
    id: stripeDiscount.id,
    coupon,
    start: new Date(stripeDiscount.start * 1000),
    end: stripeDiscount.end ? new Date(stripeDiscount.end * 1000) : null,
  };
  return discount;
};

// to extend a general payment provider customer when the time comes
class StripeCustomer {
  // optional subscriptionId to specify
  constructor(stripeCustomer, { subscriptionId } = {}) {
    let subscription;
    if (!stripeCustomer) {
      return;
    }
    if (subscriptionId) {
      subscription = stripeCustomer.subscriptions?.data.find((d) => d.id === subscriptionId);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (stripeCustomer.subscriptions?.data?.length) {
        subscription = stripeCustomer.subscriptions?.data[0];
      }
    }
    if (!subscription) {
      return;
    }
    const subscriptionItems = subscription.items.data.length ? subscription.items.data : [];
    this.customerSubscription = {
      id: subscription.id,
      isDelinquent: subscription.status === 'past_due',
      subscriptionStartDate: new Date(subscription.start_date * 1000),
      status: subscription.status,
      discount: subscription.discount,
      currentPeriodStart: new Date(subscription.current_period_start * 1000),
      currentPeriodEnd: new Date(subscription.current_period_end * 1000),
      cancelAtPeriodEnd: subscription.cancel_at_period_end,
      items: subscriptionItems,
      pauseCollection: subscription.pause_collection,
      trialStart: subscription.trial_start ? new Date(subscription.trial_start * 1000) : null,
      trialEnd: subscription.trial_end ? new Date(subscription.trial_end * 1000) : null,
    };

    if (stripeCustomer.discount) {
      this.customerDiscount = mapStripeDiscount(stripeCustomer.discount);
    }

    if (this.customerSubscription.discount) {
      this.customerSubscription.discount = mapStripeDiscount(this.customerSubscription.discount);
    }

    if (this.customerSubscription.status === 'trialing') {
      this.customerTrial = {
        start: this.customerSubscription.trialStart,
        end: this.customerSubscription.trialEnd,
      };
    }

    if (this.customerSubscription.pauseCollection) {
      this.customerSubscriptionPause = {
        // keep_as_draft, mark_uncollectible, void
        behavior: this.customerSubscription.pauseCollection.behavior,
        resumesAt: this.customerSubscription.pauseCollection.resumes_at
          ? new Date(this.customerSubscription.pauseCollection.resumes_at * 1000)
          : null,
      };
    }

    this.customerSubscriptionItems = this.customerSubscription.items.map((item) => ({
      id: item.id,
      created: new Date(item.created * 1000),
      price: item.price,
      type: item.type,
    }));

    // if (this.customerSubscriptionItems.length === 1) {
    if (this.customerSubscriptionItems.length) {
      const { price } = this.customerSubscriptionItems[0];
      this.customerPrice = {
        id: price.id,
        active: price.active,
        amount: price.unit_amount,
        billingScheme: price.billing_scheme,
        created: new Date(price.created * 1000),
        currency: price.currency,
        livemode: price.livemode,
        interval: price.recurring && price.recurring.interval,
        intervalCount: price.recurring && price.recurring.interval_count,
        type: price.type,
      };
    }

    if (this.customerPrice && this.customerPrice.interval) {
      const stripeIntervalMap = {
        week: SUBSCRIPTION_INTERVAL.WEEK,
        month: SUBSCRIPTION_INTERVAL.MONTH,
        year: SUBSCRIPTION_INTERVAL.YEAR,
      };
      this.customerBillingInterval = stripeIntervalMap[this.customerPrice.interval];
      this.customerBillingIntervalCount = this.customerPrice.intervalCount;
    }

    this.defaultSource = stripeCustomer.default_source;
  }
}

export default StripeCustomer;
