<template>
  <div class="h-screen flex overflow-hidden bg-white relative">
    <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
    <div v-show="mobileMenuOpen" class="block sm:hidden bg-gray-800">
      <SidebarMobileContent />
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0 items-stretch">
      <SidebarDesktopContent />
    </div>

    <div class="flex flex-col w-0 flex-1 overflow-hidden bg-brand-sand text-brand-brown">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md">
          <span class="sr-only">Open sidebar</span>
          <!-- Mobile menu button-->
          <button class="inline-flex items-center justify-center p-2 rounded-md focus:outline-none" aria-label="Main menu" aria-expanded="false">
            <!-- Icon when menu is closed. -->
            <!--
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
              v-show="!mobileMenuOpen"
              @click="mobileMenuOpen = true"
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <!-- Icon when menu is open. -->
            <!--
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
              v-show="mobileMenuOpen"
              @click="mobileMenuOpen = false"
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </button>
      </div>
      <div class="flex-1 relative bg-brand-light-sand" data-posthog-scroll-root="true">
        <div class="absolute inset-0 overflow-y-auto" data-posthog-attributes-include="scrollTop,scrollHeight"
        >
          <div class="min-h-full">
            <DataControls />
            <div class="px-6 pt-8 pb-6 space-y-4 lg:space-y-6 3xl:container 3xl:max-w-screen-2xl max-w-screen-2xl">
              <TabbedNav />
              <main>
                <slot />
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataControls from './DataControls.vue';
import SidebarDesktopContent from './SidebarDesktopContent.vue';
import SidebarMobileContent from './SidebarMobileContent.vue';
import TabbedNav from './TabbedNav.vue';

export default {
  name: 'SidebarLayout',
  components: {
    DataControls,
    SidebarDesktopContent,
    SidebarMobileContent,
    TabbedNav,
  },
  data() {
    return {
      mobileMenuOpen: false,
    };
  },
  mounted() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          const { target } = el.dataset;
          const $target = document.getElementById(target);
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  },

  watch: {
    // eslint-disable-next-line
    $route() {
      this.mobileMenuOpen = false;
      const elements = [document.getElementById('navbarMenu'), document.getElementById('navbarMenuBurger')];
      elements.forEach((el) => {
        if (el) {
          el.classList.remove('is-active');
        }
      });
    },
  },
};
</script>
