export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';
export const REGISTER = 'REGISTER';
export const SWITCH_ORG = 'SWITCH_ORG';
export const OPTIMISTIC_ORG_UPDATE = 'OPTIMISTIC_ORG_UPDATE';
export const FETCH_ORG_COUPONS = 'FETCH_ORG_COUPONS';
export const FETCH_ORG_PRICES = 'FETCH_ORG_PRICES';
export const FETCH_ORG_PRODUCTS = 'FETCH_ORG_PRODUCTS';
export const SYNC_USER_RECORD = 'SYNC_USER_RECORD';
export const SYNC_ORG = 'SYNC_ORG';
export const SYNC_ORG_REFRESH_STRIPE = 'SYNC_ORG_REFRESH_STRIPE';
export const FAILED_PAYMENT_CHECK = 'FAILED_PAYMENT_CHECK';
export const CREATE_BLUEPRINT = 'CREATE_BLUEPRINT';
export const CREATE_STEP = 'CREATE_STEP';
export const CREATE_OFFER = 'CREATE_OFFER';
export const SYNC_BLUEPRINT = 'SYNC_BLUEPRINT';
export const BLUEPRINT_GET = 'BLUEPRINT_GET';
export const BLUEPRINT_UPDATE = 'BLUEPRINT_UPDATE';
export const BLUEPRINT_SCHEDULE_SAVE = 'BLUEPRINT_SCHEDULE_SAVE';
export const BLUEPRINT_PUBLISH = 'BLUEPRINT_PUBLISH';
export const BLUEPRINT_SEGMENT_PUBLISH = 'BLUEPRINT_SEGMENT_PUBLISH';
export const SEGMENT_CREATE = 'SEGMENT_CREATE';
export const SEGMENT_GET = 'SEGMENT_GET';
export const SEGMENT_UPDATE = 'SEGMENT_UPDATE';
export const SEGMENT_CLONE = 'SEGMENT_CLONE';
export const CREATE_AB_TEST = 'CREATE_AB_TEST';
export const UPDATE_AB_TEST = 'UPDATE_AB_TEST';
export const FETCH_AB_TESTS = 'FETCH_AB_TESTS';
export const UPDATE_SEGMENT_ORDER = 'UPDATE_SEGMENT_ORDER';
export const SEGMENT_SCHEDULE_SAVE = 'SEGMENT_SCHEDULE_SAVE';
export const PLAN_SETTINGS_SCHEDULE_SAVE = 'PLAN_SETTINGS_SCHEDULE_SAVE';
export const SEGMENT_PUBLISH = 'SEGMENT_PUBLISH';
export const WEBHOOK_CREATE = 'WEBHOOK_CREATE';
export const WEBHOOK_FETCH_ALL = 'WEBHOOK_FETCH_ALL';
export const WEBHOOK_UPDATE = 'WEBHOOK_UPDATE';
export const REMOVE_SLACK_CONNECTION = 'REMOVE_SLACK_CONNECTION';
export const LAUNCH_EMBED_PREVIEW = 'LAUNCH_EMBED_PREVIEW';
export const ORG_NOTIFICATIONS_UPDATE = 'ORG_NOTIFICATIONS_UPDATE';
export const ORG_BRANDING_UPDATE = 'ORG_BRANDING_UPDATE';
export const VERIFY_MAGIC_LINK_AUTH_TOKEN = 'VERIFY_MAGIC_LINK_AUTH_TOKEN';
export const SYNC_ANALYTICS = 'SYNC_ANALYTICS';
export const GET_ISSUES = 'GET_ISSUES';
export const UPDATE_ISSUE = 'UPDATE_ISSUE';
export const ADMIN_SET_ORG_SETTING = 'ADMIN_SET_ORG_SETTING';

// Dashboard
export const FETCH_BOOSTED_REVENUE_STATS = 'FETCH_BOOSTED_REVENUE_STATS';
export const DOWNLOAD_SESSIONS = 'DOWNLOAD_SESSIONS';
export const DOWNLOAD_SESSIONS_NEXT = 'DOWNLOAD_SESSIONS_NEXT';
export const DOWNLOAD_DATA_RECORDS = 'DOWNLOAD_DATA_RECORDS';
export const GET_SESSIONS_AND_BLUEPRINTS = 'GET_SESSIONS_AND_BLUEPRINTS';
export const SESSIONS_GET = 'SESSIONS_GET';
export const EXPORT_DATA = 'EXPORT_DATA';
export const FETCH_SURVEY_RESPONSES = 'FETCH_SURVEY_RESPONSES';
export const FETCH_SESSION_OUTCOMES = 'FETCH_SESSION_OUTCOMES';
export const FETCH_OFFER_OUTCOMES = 'FETCH_OFFER_OUTCOMES';
export const FETCH_RECENT_SAVES = 'FETCH_RECENT_SAVES';
export const FETCH_RECENT_SESSIONS = 'FETCH_RECENT_SESSIONS';
export const FETCH_TEST_SESSIONS = 'FETCH_TEST_SESSIONS';
export const FETCH_SESSION_FILTER_VALUES = 'FETCH_SESSION_FILTER_VALUES';
export const RETURN_SESSION_OUTCOMES = 'RETURN_SESSION_OUTCOMES';
export const LAUNCH_INLINE_PREVIEW = 'LAUNCH_INLINE_PREVIEW';
export const FETCH_CUSTOMER_PROFILE = 'FETCH_CUSTOMER_PROFILE';
export const FETCH_AB_SESSION_COUNTS = 'FETCH_AB_SESSION_COUNTS';
export const FETCH_SEGMENT_SESSION_COUNTS = 'FETCH_SEGMENT_SESSION_COUNTS';

// Dunning
export const SYNC_DUNNING = 'SYNC_DUNNING';
export const SYNC_DUNNING_CAMPAIGN_BLUEPRINTS = 'SYNC_DUNNING_CAMPAIGN_BLUEPRINTS';
export const SYNC_DUNNING_EXCLUSIONS = 'SYNC_DUNNING_EXCLUSIONS';
export const CREATE_DUNNING_CAMPAIGN_BLUEPRINT = 'CREATE_DUNNING_CAMPAIGN_BLUEPRINT';
export const FETCH_DUNNING_CAMPAIGN_BLUEPRINT = 'FETCH_DUNNING_CAMPAIGN_BLUEPRINT';
export const UPDATE_DUNNING_CAMPAIGN_BLUEPRINT = 'UPDATE_DUNNING_CAMPAIGN_BLUEPRINT';
export const UPDATE_DUNNING_CAMPAIGN_BLUEPRINTS_ORDER = 'UPDATE_DUNNING_CAMPAIGN_BLUEPRINTS_ORDER';
export const CLONE_DUNNING_CAMPAIGN_BLUEPRINT = 'CLONE_DUNNING_CAMPAIGN_BLUEPRINT';
export const PUBLISH_DUNNING_CAMPAIGN_BLUEPRINT = 'PUBLISH_DUNNING_CAMPAIGN_BLUEPRINT';
export const SCHEDULE_SAVE_DUNNING_CAMPAIGN_BLUEPRINT = 'SCHEDULE_SAVE_DUNNING_CAMPAIGN_BLUEPRINT';
export const DUNNING_WAITLIST_SIGNUP = 'DUNNING_WAITLIST_SIGNUP';
// export const DELETE_EMAIL_BLUEPRINT = 'DELETE_EMAIL_BLUEPRINT';
export const CREATE_DUNNING_EXCLUSION = 'CREATE_DUNNING_EXCLUSION';
export const UPDATE_DUNNING_EXCLUSION = 'UPDATE_DUNNING_EXCLUSION';
export const UPDATE_DUNNING_EXCLUSIONS_ORDER = 'UPDATE_DUNNING_EXCLUSIONS_ORDER';
export const CLONE_DUNNING_EXCLUSION = 'CLONE_DUNNING_EXCLUSION';
export const PUBLISH_DUNNING_EXCLUSION = 'PUBLISH_DUNNING_EXCLUSION';
export const SCHEDULE_SAVE_DUNNING_EXCLUSION = 'SCHEDULE_SAVE_DUNNING_EXCLUSION';
export const SEND_DUNNING_TEST_EMAIL = 'SEND_DUNNING_TEST_EMAIL';
export const GENERATE_DUNNING_EMAIL_PREVIEW = 'GENERATE_DUNNING_EMAIL_PREVIEW';
export const SEND_REACTIVATION_TEST_EMAIL = 'SEND_REACTIVATION_TEST_EMAIL';
export const SEND_DUNNING_TEST_SMS = 'SEND_DUNNING_TEST_SMS';
export const FETCH_DUNNING_CAMPAIGN_COUNTS = 'FETCH_DUNNING_CAMPAIGN_COUNTS';

// Dunning dashboard
export const FETCH_EMAIL_TIMELINE = 'FETCH_EMAIL_TIMELINE';

// Reactivation
export const SYNC_REACTIVATION = 'SYNC_REACTIVATION';
export const SYNC_REACTIVATION_CAMPAIGN_BLUEPRINTS = 'SYNC_REACTIVATION_CAMPAIGN_BLUEPRINTS';
export const SYNC_REACTIVATION_EXCLUSIONS = 'SYNC_REACTIVATION_EXCLUSIONS';
export const CREATE_REACTIVATION_CAMPAIGN_BLUEPRINT = 'CREATE_REACTIVATION_CAMPAIGN_BLUEPRINT';
export const FETCH_REACTIVATION_CAMPAIGN_BLUEPRINT = 'FETCH_REACTIVATION_CAMPAIGN_BLUEPRINT';
export const UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINT = 'UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINT';
export const UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINTS_ORDER = 'UPDATE_REACTIVATION_CAMPAIGN_BLUEPRINTS_ORDER';
export const CLONE_REACTIVATION_CAMPAIGN_BLUEPRINT = 'CLONE_REACTIVATION_CAMPAIGN_BLUEPRINT';
export const PUBLISH_REACTIVATION_CAMPAIGN_BLUEPRINT = 'PUBLISH_REACTIVATION_CAMPAIGN_BLUEPRINT';
export const SCHEDULE_SAVE_REACTIVATION_CAMPAIGN_BLUEPRINT = 'SCHEDULE_SAVE_REACTIVATION_CAMPAIGN_BLUEPRINT';
export const REACTIVATION_WAITLIST_SIGNUP = 'REACTIVATION_WAITLIST_SIGNUP';
export const CREATE_REACTIVATION_EXCLUSION = 'CREATE_REACTIVATION_EXCLUSION';
export const UPDATE_REACTIVATION_EXCLUSION = 'UPDATE_REACTIVATION_EXCLUSION';
export const UPDATE_REACTIVATION_EXCLUSIONS_ORDER = 'UPDATE_REACTIVATION_EXCLUSIONS_ORDER';
export const CLONE_REACTIVATION_EXCLUSION = 'CLONE_REACTIVATION_EXCLUSION';
export const PUBLISH_REACTIVATION_EXCLUSION = 'PUBLISH_REACTIVATION_EXCLUSION';
export const SCHEDULE_SAVE_REACTIVATION_EXCLUSION = 'SCHEDULE_SAVE_REACTIVATION_EXCLUSION';
export const FETCH_RECENT_SURVEY_RESPONSES = 'FETCH_RECENT_SURVEY_RESPONSES';
export const FETCH_REACTIVATION_CAMPAIGN_COUNTS = 'FETCH_REACTIVATION_CAMPAIGN_COUNTS';
export const FETCH_REACTIVATION_BLOCK_COUNTS = 'FETCH_REACTIVATION_BLOCK_COUNTS';
export const FETCH_REACTIVATION_EMAIL_OPEN_COUNTS = 'FETCH_REACTIVATION_EMAIL_OPEN_COUNTS';
export const FETCH_REACTIVATION_EMAIL_TIMELINE = 'FETCH_REACTIVATION_EMAIL_TIMELINE';

// Subscription analytics dashboard
export const FETCH_EVENT_ROLLUP = 'FETCH_EVENT_ROLLUP';
export const FETCH_EVENT_FILTER_OPTIONS = 'FETCH_EVENT_FILTER_OPTIONS';
export const DOWNLOAD_DUNNING_CAMPAIGNS = 'DOWNLOAD_DUNNING_CAMPAIGNS';
export const DOWNLOAD_REACTIVATION_CAMPAIGNS = 'DOWNLOAD_REACTIVATION_CAMPAIGNS';

// AI
export const AI_LOAD_JOBS = 'AI_LOAD_JOBS';
export const AI_COMPLETE_JOB = 'AI_COMPLETE_JOB';
export const AI_CANCEL_JOB = 'AI_CANCEL_JOB';
export const AI_GENERATE_BLUEPRINT = 'AI_GENERATE_BLUEPRINT';
export const AI_REWORK_BLUEPRINT = 'AI_REWORK_BLUEPRINT';
export const AI_REWORK_STEP = 'AI_REWORK_STEP';
export const AI_REWORK_SURVEY_CHOICE = 'AI_REWORK_SURVEY_CHOICE';

// Permissions
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';

// Churn metrics dashboard
export const CHURN_METRICS_LOAD_BILLING_METRICS = 'CHURN_METRICS_LOAD_BILLING_METRICS';

// Provider
export const FETCH_PROVIDER_FEATURES = 'FETCH_PROVIDER_FEATURES';
