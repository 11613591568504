import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngellist, faFacebook, faGithub, faInstagram, faLinkedinIn, faStripe, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDoubleUp,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faArrowUpWideShort,
  faAward,
  faBold,
  faBullhorn,
  faCalendarWeek,
  faCaretDown,
  faCaretUp,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faClipboard,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCogs,
  faComment,
  faCommentDollar,
  faCommentDots,
  faComments,
  faCommentSms,
  faCreditCard,
  faDatabase,
  faDollarSign,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFileInvoiceDollar,
  faFilter,
  faFlag,
  faGem,
  faGlobe,
  faGripVertical,
  faHeart,
  faHourglassHalf,
  faHourglassStart,
  faImage,
  faInfo,
  faInfoCircle,
  faItalic,
  faKey,
  faLayerGroup,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltUp,
  faMagic,
  faMarker,
  faMedal,
  faMinus,
  faPaperPlane,
  faParagraph,
  faPaste,
  faPause,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlus,
  faQuestion,
  faQuoteLeft,
  faQuoteRight,
  faReceipt,
  faRedo,
  faRedoAlt,
  faServer,
  faShapes,
  faShare,
  faSquare,
  faStar,
  faStrikethrough,
  faTag,
  faTimesCircle,
  faUnderline,
  faUndoAlt,
  faUnlink,
  faUpRightFromSquare,
  faUser,
  faArrowPointer,
  faUsers,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

library.add(
  faCalendarWeek,
  faPlug,
  faCircle,
  faCheckCircle,
  faReceipt,
  faQuestion,
  faPlayCircle,
  faPlay,
  faPause,
  faFlag,
  faFilter,
  faMarker,
  faGripVertical,
  faEnvelope,
  faPaperPlane,
  faStripe,
  faFileInvoiceDollar,
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faAngellist,
  faGithub,
  faPaste,
  faEllipsisV,
  faVideoSlash,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faParagraph,
  faListUl,
  faListOl,
  faUndoAlt,
  faRedoAlt,
  faArrowLeft,
  faUpRightFromSquare,
  faEye,
  faCloudUploadAlt,
  faCloud,
  faImage,
  faMinus,
  faCheck,
  faAngleDown,
  faAngleDoubleUp,
  faInfo,
  faInfoCircle,
  faMagic,
  faLayerGroup,
  faTag,
  faChartPie,
  faChartArea,
  faChartLine,
  faChartBar,
  faHourglassHalf,
  faHourglassStart,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faServer,
  faLock,
  faSquare,
  faCheck,
  faDatabase,
  faQuoteLeft,
  faQuoteRight,
  faCode,
  faCommentDollar,
  faComment,
  faShapes,
  faAward,
  faStar,
  faMedal,
  faComments,
  faCommentDots,
  faExternalLinkAlt,
  faTimesCircle,
  faCheckDouble,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faClock,
  faExclamation,
  faDollarSign,
  faCreditCard,
  faClipboard,
  faChevronRight,
  faChevronLeft,
  faExclamationTriangle,
  faShare,
  faArrowPointer,
  faUser,
  faUsers,
  faChevronUp,
  faChevronDown,
  faLink,
  faUnlink,
  faLongArrowAltUp,
  faCaretUp,
  faCaretDown,
  faPlus,
  faArrowUp,
  faArrowDown,
  faCog,
  faCogs,
  faGlobe,
  faKey,
  faBullhorn,
  faRedo,
  faHeart,
  faArrowUpWideShort,
  faGem,
  faCommentSms
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
