/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable func-names */
// import deepEqual from 'fast-deep-equal';
import { Route, RouteRecord, RouteConfig } from 'vue-router';
import { dateFormat } from '@/helpers/date-utils';
import {
  PRICE_PLAN,
  STEP_TYPE,
  STEP_PLACE,
  SAVE_STATE,
  LOAD_STATE,
  DISCOUNT_TYPE,
  discountCouponToString,
  SEGMENT_ATTRIBUTE,
  SEGMENT_ATTRIBUTE_TYPE,
  CUSTOM_ATTRIBUTE_TYPE,
  segmentAttributeLabels,
  segmentOperandLabels,
  segmentDateOperandLabels,
  paddleCouponIdToCoupon,
  SEGMENT_OPERAND,
  PAUSE_INTERVAL,
  CUSTOM_DISCOUNT_DURATION,
  PADDLE_VERSION,
} from '@/store/shared';
import { timeDay } from 'd3-time';
import { languageLabels } from '@/helpers/languages';
import { hasNonMonthlySubscription as hasNonMonthlySubscriptionHelper } from '@/helpers/subscriptions';
import routeConfigs from '@/router/routes';
import { StripeSubscriptionStatus } from './stripe-types';
import {
  BlueprintStep,
  ICoupon,
  IPlanSummary,
  IDecoratedSession,
  isISurveyStep,
  IState,
  IABTest,
  ISurveyChoice,
  IOfferPresented,
  ISlackConnection,
  ISegmentFilter,
  SegmentFilterValue,
  IOfferOutcomeCount,
  SegmentAttribute,
  isIOfferStep,
  RouteMeta,
  NavGroup,
  NAV_GROUP,
  ValueOrGetter,
  PaddleVersion,
} from './types';
import StripeCustomer from './StripeCustomer';
import { USER_ROLE } from './modules/permissions';
import { Offer, OfferType } from '@/models/Offer';

const enableNewGetters = true;

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export const isLoggedIn = (state: IState) => !!state.token;
export const authStatus = (state: IState) => state.authStatus;

export const isAuthorizedRoute = (state: IState, getters: any) => (route: Route | RouteRecord | RouteConfig) => {
  const { requiresAuth, requiresAdmin, available, requiresFeature } = (route.meta || {}) as RouteMeta;
  if (requiresAuth === true && !getters.isLoggedIn) return false;
  if (requiresAdmin === true && !getters.isAdmin) return false;
  if (requiresFeature && state.org && state.org[requiresFeature] !== true) return false;
  if (typeof available !== 'undefined' && !getters.evalBoolean(available)) return false;
  return true;
};

const navGroups: NavGroup[] = routeConfigs.reduce((groups, route) => {
  const keys: NAV_GROUP[] = route.meta?.navGroups || [];
  keys.forEach((key) => {
    const group = groups.find((g) => g.key === key);
    if (group) {
      group.routes.push(route);
    } else {
      const name = key === NAV_GROUP.MAIN ? '' : key;
      groups.push({ key, name, routes: [route] });
    }
  });
  return groups;
}, [] as NavGroup[]);

export const authorizedNavGroups = (state: IState, getters: any) => {
  return navGroups
    .map((group: NavGroup) => ({
      ...group,
      routes: group.routes.filter((route: RouteConfig) => getters.isAuthorizedRoute(route)),
    }))
    .filter((group: NavGroup) => !!group.routes.length);
};

export const routeLabel = (state: IState, getters: any) => (route: RouteConfig) => {
  if (route.meta?.label) {
    return getters.evalString(route.meta?.label);
  }

  if (route.name) return route.name.replace(/-/g, ' ');

  // Fallback to last path component
  const components = route.path.split('/');
  return components[components.length - 1].replace(/-/g, ' ');
};

export const isLocal = (state: IState) => {
  return import.meta.env.VITE_API_ENDPOINT === 'http://localhost:3000/v1';
};
export const isAdmin = (state: IState, getters: any) => {
  if (getters.isLocal && state.user && [USER_ROLE.SUPER_ADMIN, USER_ROLE.SUPER_MEMBER, USER_ROLE.SUPER_VIEWER].includes(state.user.role)) {
    return true;
  }
  if (
    state.org?._id &&
    state.user &&
    (state.org._id === '61c22cdead284eac235005ab' || state.asOrg) &&
    [USER_ROLE.SUPER_ADMIN, USER_ROLE.SUPER_MEMBER, USER_ROLE.SUPER_VIEWER].includes(state.user.role)
  ) {
    return true;
  }
  return false;
};
export const isOwner = (state: IState, getters: any) => {
  return getters.isAdmin || state.user?.role === USER_ROLE.ORG_OWNER;
};

export const isViewOnly = (state: IState, getters: any) => {
  return state.user?.role === USER_ROLE.ORG_VIEWER || state.user?.role === USER_ROLE.SUPER_VIEWER;
};

export const stripeAccountId = (state: IState) => {
  return state.org?.stripeAccountId;
};

export const hasLegacyPricing = (state: IState) => {
  return state.org?.legacyPricing;
};

// blueprint centric
export const currentBlueprintId = (state: IState) => state.currentBlueprintId;

export const previewInline = (state: IState) => state.previewInline;

// non-segmented blueprint
export const primaryBlueprintId = (state: IState) => {
  if (state.org?.blueprints.length) {
    return state.org.blueprints[0];
  }
  return null;
};

export const currentOrgSettingsSaveState = (state: IState) => {
  if (state.org?.saveState) {
    return state.org?.saveState;
  }
  return SAVE_STATE.SAVED;
};

// non-segmented blueprint
export const primaryBlueprint = (state: IState, getters: any) => {
  if (getters.primaryBlueprintId && state.blueprints[getters.primaryBlueprintId]) {
    return state.blueprints[getters.primaryBlueprintId];
  }
  return null;
};

export const abTests = (state: IState, getters: any): IABTest[] => {
  if (state.org?.abTests) {
    return state.org?.abTests;
  }
  return [];
};

export const currentBlueprint = (state: IState) => {
  if (state.currentBlueprintId && state.blueprints[state.currentBlueprintId]) {
    return state.blueprints[state.currentBlueprintId];
  }
  return null;
};

// based on currentBlueprintId
export const currentSegment = (state: IState, getters: any) => {
  if (!getters.currentBlueprint) {
    return null;
  }
  const segmentId = getters.currentBlueprint.segment;
  if (state.segments[segmentId]) {
    return state.segments[segmentId];
  }
  return null;
};
export const currentSegmentSaveState = (state: IState, getters: any) => {
  if (getters.currentSegment) {
    return getters.currentSegment.saveState || SAVE_STATE.SAVED;
  }
  return undefined;
};
export const currentBlueprintSaveState = (state: IState) => {
  if (state.currentBlueprintId && state.blueprints[state.currentBlueprintId]) {
    return state.blueprints[state.currentBlueprintId].saveState || SAVE_STATE.SAVED;
  }
  return undefined;
};
export const currentBlueprintLoadState = (state: IState) => {
  if (state.currentBlueprintId && state.blueprints[state.currentBlueprintId]) {
    return state.blueprints[state.currentBlueprintId].loadState || LOAD_STATE.UNLOADED;
  }
  return LOAD_STATE.UNLOADED;
};
export const currentBlueprintSegment = (state: IState) => {
  if (state.currentBlueprintId && state.blueprints[state.currentBlueprintId]) {
    return state.blueprints[state.currentBlueprintId].loadState || LOAD_STATE.UNLOADED;
  }
  return LOAD_STATE.UNLOADED;
};
// orgs active blueprint (the published one)
export const activeBlueprint = (state: IState) => {
  if (state.org && state.org.activeBlueprint && state.blueprints[state.org.activeBlueprint]) {
    return state.blueprints[state.org.activeBlueprint];
  }
  return null;
};
// does the current blueprint look like the one that is published
export const currentBlueprintMatchesActive = (state: IState, getters: any) => {
  // eslint-disable-next-line prefer-destructuring
  const currentBlueprintDoc = getters.currentBlueprint;
  // eslint-disable-next-line prefer-destructuring
  const activeBlueprintDoc = getters.activeBlueprint;
  if (activeBlueprintDoc && currentBlueprintDoc) {
    if (activeBlueprintDoc.guid !== currentBlueprintDoc.guid) {
      return false;
    }
    return activeBlueprintDoc.guid === currentBlueprintDoc.guid && currentBlueprintDoc.updatedAt === activeBlueprintDoc.publishedAt;
    // alternatively deep check
    // return (
    //   activeBlueprintDoc.guid === currentBlueprintDoc.guid &&
    //   deepEqual(currentBlueprintDoc.steps, activeBlueprintDoc.steps) &&
    //   deepEqual(currentBlueprintDoc.primaryColor, activeBlueprintDoc.primaryColor)
    // );
  }
  return false;
};

export const publishedBlueprintMatchesActive = (state: IState, getters: any) => (blueprintId: string) => {
  // find published (active) version for segment
  const blueprintDoc = state.blueprints[blueprintId];
  let activeBlueprintId = state.org?.activeBlueprint;
  if (blueprintDoc.segment) {
    activeBlueprintId = state.segments[blueprintDoc.segment].activeBlueprint;
  }
  if (!activeBlueprintId) {
    return false;
  }
  const activeBlueprintDoc = state.blueprints[activeBlueprintId];
  if (activeBlueprintDoc && blueprintDoc) {
    if (activeBlueprintDoc.guid !== blueprintDoc.guid) {
      return false;
    }
    return activeBlueprintDoc.guid === blueprintDoc.guid && blueprintDoc.updatedAt === activeBlueprintDoc.publishedAt;
  }
  return false;
};

// does the current blueprint look like the one that is published on the current segment
export const currentBlueprintMatchesSegmentActive = (state: IState, getters: any) => {
  const segment = getters.currentSegment;
  if (!segment || !segment.activeBlueprint) {
    return false;
  }
  // eslint-disable-next-line prefer-destructuring
  const currentBlueprintDoc = getters.currentBlueprint;
  // eslint-disable-next-line prefer-destructuring
  const activeBlueprintDoc = state.blueprints[segment.activeBlueprint];
  if (activeBlueprintDoc && currentBlueprintDoc && segment) {
    if (activeBlueprintDoc.guid !== currentBlueprintDoc.guid) {
      return false;
    }
    return activeBlueprintDoc.guid === currentBlueprintDoc.guid && currentBlueprintDoc.updatedAt === activeBlueprintDoc.publishedAt;
  }
  return false;
};

// eslint-disable-next-line arrow-body-style
export const surveyChoices = (state: IState) => {
  return Object.keys(state.blueprints).reduce(
    (surveyChoiceAcc, blueprintId) => {
      const surveyStep = state.blueprints[blueprintId]?.steps?.find((s) => s.stepType === STEP_TYPE.SURVEY);
      if (surveyStep && isISurveyStep(surveyStep)) {
        const choices = surveyStep.survey.choices.reduce(
          (acc, choice) => {
            acc[choice.guid] = choice;
            return acc;
          },
          {} as { [key: string]: ISurveyChoice }
        );
        surveyChoiceAcc[blueprintId] = choices;
      }
      return surveyChoiceAcc;
    },
    {} as { [blueprintKey: string]: { [choiceKey: string]: ISurveyChoice } }
  );
};

// old
export const blueprintStepMap = (state: IState, getters: any): { [key: string]: BlueprintStep } => {
  if (!getters.currentBlueprint || !getters.currentBlueprint.steps) {
    return {};
  }
  return Object.keys(STEP_TYPE).reduce(
    (map, type) => {
      const step = getters.currentBlueprint?.steps.find((s: BlueprintStep) => s.stepType === type);
      if (step) {
        map[type] = step;
      }
      return map;
    },
    {} as { [key: string]: BlueprintStep }
  );
};

export const blueprintPlaceMap = (state: IState, getters: any): { [key: string]: BlueprintStep } => {
  if (!getters.currentBlueprint || !getters.currentBlueprint.steps) {
    return {};
  }
  const placeMap = {} as { [key: string]: BlueprintStep };
  // based on stepType + place in blueprint steps (to discern between initial offer and final offer)
  getters.currentBlueprint.steps.forEach((step: BlueprintStep, index: number) => {
    if (step.stepType === STEP_TYPE.OFFER) {
      if (index === 0) {
        placeMap[STEP_PLACE.INITIAL_OFFER] = step;
      } else {
        placeMap[STEP_PLACE.FINAL_OFFER] = step;
      }
    } else {
      placeMap[step.stepType] = step;
    }
  });
  return placeMap;
};

// stripe coupons mapped into generic
export const coupons = (state: IState): ICoupon[] => {
  if (state.stripeData.coupons) {
    return state.stripeData.coupons.map((coupon) => {
      const discountType = coupon.percent_off ? DISCOUNT_TYPE.PERCENT : DISCOUNT_TYPE.AMOUNT;
      return {
        discountType,
        id: coupon.id,
        duration: coupon.duration,
        months: coupon.duration_in_months,
        name: coupon.name,
        percentOff: coupon.percent_off,
        amountOff: coupon.amount_off,
        maxRedemptions: coupon.max_redemptions,
        valid: coupon.valid,
        redeemBy: coupon.redeem_by ? new Date(coupon.redeem_by * 1000) : null,
        created: coupon.created ? new Date(coupon.created * 1000) : null,
        currency: coupon.currency,
      };
    });
  }
  return [];
};

export const filteredCoupons = (state: IState, getters: any): ICoupon[] => {
  if (state.providerFeatures.actions.pause.requiredCoupon) {
    return getters.coupons.filter((c: ICoupon) => c.id !== state.providerFeatures.actions.pause.requiredCoupon);
  }
  return getters.coupons;
};

export const couponMap = (state: IState, getters: any): { [key: string]: ICoupon } => {
  if (!getters.coupons) {
    return {};
  }
  return getters.coupons.reduce(
    (acc: { [key: string]: ICoupon }, coupon: ICoupon) => {
      acc[coupon.id] = coupon;
      return acc;
    },
    {} as { [key: string]: ICoupon }
  );
};

export interface IUserOrgSubscription {
  isDelinquent: boolean;
  subscriptionStartDate: Date;
  status: StripeSubscriptionStatus;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  cancelAtPeriodEnd: boolean;
  planPriceId: string;
  plan: IPlanSummary;
}
export const userOrgSubscription = (state: IState): IUserOrgSubscription | null => {
  try {
    if (state.org && state.org.stripe) {
      if (!(state.org.stripe.subscriptions && state.org.stripe.subscriptions.data.length)) {
        return null;
      }
      const subscription = state.org.stripe.subscriptions.data[0];
      const subscriptionPlan = subscription.plan || subscription.items?.data[0]?.plan;
      const totalAmount = subscription.items.data.reduce((acc, item) => acc + (item.price.unit_amount || 0), 0);
      let plan = PRICE_PLAN[subscriptionPlan.id];
      const interval = subscriptionPlan.interval;
      let amount = subscriptionPlan.amount;

      const description = `${totalAmount / 100}/${interval} fixed rate`;
      if (!plan) {
        amount = totalAmount || 0;
        plan = {
          name: 'Premium',
          metered: false,
          amount,
          description,

          interval: subscriptionPlan.interval || 'month',
        };
      }
      return {
        isDelinquent: subscription.status === 'past_due',
        subscriptionStartDate: new Date(subscription.start_date * 1000),
        status: subscription.status,
        currentPeriodStart: new Date(subscription.current_period_start * 1000),
        currentPeriodEnd: new Date(subscription.current_period_end * 1000),
        cancelAtPeriodEnd: subscription.cancel_at_period_end,
        planPriceId: subscriptionPlan.id,
        plan,
      };
    }
    return null;
  } catch (e) {
    console.error('Error in getter:', e);
    // catch a "cannot read property of undefined" instead of nested checks
    return null;
  }
};

export const orgCustomer = (state: IState): any => {
  if (state.org && state.org.stripe) {
    return new StripeCustomer(state.org.stripe);
  }
  return null;
};

export const orgTranslations = (state: IState): any => {
  const defaultTranslations = { en: {}, es: {}, fr: {}, de: {} };
  if (state.org && state.org.translations) {
    return { ...defaultTranslations, ...state.org.translations };
  }
  return { ...defaultTranslations };
};

export const orgLanguages = (state: IState, getters: any): { value: string; label: string }[] => {
  let languageSet = ['en'];
  if (state.org && state.org.translations) {
    // just languages where object isn't empty

    languageSet = Object.keys(state.org.translations).filter((k) => k === 'en' || !isEmpty(state.org.translations[k]));
  }
  return languageSet.map((language) => {
    return {
      value: language,

      label: languageLabels[language]?.englishName || 'language',
    };
  });
};

export const orgPlanSettings = (state: IState): any => {
  if (state.org && state.org.plans && state.org.plans.length) {
    return state.org.plans;
  }
  return null;
};

export const orgProviderSettings = (state: IState): any =>
  state?.org?.providerSettings || {
    planChangeProrate: true,
    planChangeImmediate: true,
    pauseEndOfTerm: false,
  };

export const confidential = (state: IState): boolean => {
  // return true;
  return state.confidential;
};

export const testMode = (state: IState): boolean => {
  return state.testMode;
};

export const customerPrice = (state: IState, getters: any) => {
  if (getters.orgCustomer && getters.orgCustomer.customerPrice) {
    return getters.orgCustomer.customerPrice;
  }
  return null;
};

export const isLiteSubscriber = (state: IState, getters: any): boolean => {
  if (getters.userOrgSubscription) {
    return getters.userOrgSubscription.plan && getters.userOrgSubscription.plan.lite;
  }
  return false;
};

export const segmentsEnabled = (state: IState, getters: any) => {
  return true;
  // if (getters.userOrgSubscription) {
  //   if (!getters.isLiteSubscriber) {
  //     return true;
  //   }
  // }
  // return false;
};

export const org = (state: IState) => {
  if (state.org) {
    return state.org;
  }
  return undefined;
};

export const precisionRetriesEnabled = (state: IState) => {
  if (state.org) {
    return state.org.smartRetries && state.org.smartRetries.enabled;
  }
  return false;
};

// eslint-disable-next-line consistent-return
export const orgCreatedDate = (state: IState) => {
  if (state.org && state.org.createdAt) {
    return new Date(state.org.createdAt);
  }
};

// eslint-disable-next-line consistent-return
export const daysSinceOrgCreated = (state: IState) => {
  if (state.org && state.org.createdAt) {
    return timeDay.count(new Date(state.org.createdAt), new Date());
  }
};

export const orgBranding = (state: IState): any => state.org?.branding || { primaryColor: '#4a4a4f' };

// based on "authorized" field (or backup, if secrets are present)
export const confirmedPaymentProvider = (state: IState, getters: any): string | null => {
  if (state.org) {
    if (state.org.provider?.type) {
      return state.org.provider.type;
    }
    if (state.org.braintreeAuthorized) {
      return 'braintree';
    }
    if (state.org.stripeAuthorized || state.org.stripeAccountId) {
      return 'stripe';
    }
    if (state.org.chargebeeAuthorized) {
      return 'chargebee';
    }
    if (state.org.paddleAuthorized) {
      return getters.paddleWithoutVersion;
    }
    if (state.org.secrets) {
      // look through prod credentials first, then test credentials
      if (state.org.secrets.stripeAccountId) {
        return 'stripe';
      }
      if (state.org.secrets.prod) {
        const p = state.org.secrets.prod;
        if (p.braintreeMerchantId) {
          return 'braintree';
        }
      }
      if (state.org.secrets.prod) {
        const t = state.org.secrets.test;
        if (t.braintreeMerchantId) {
          return 'braintree';
        }
      }
      if (state.org.secrets.prod) {
        const p = state.org.secrets.prod;
        if (p.chargebeeSite) {
          return 'chargebee';
        }
      }
      if (state.org.secrets.prod) {
        const t = state.org.secrets.test;
        if (t.chargebeeSite) {
          return 'chargebee';
        }
      }
      if (state.org.secrets.prod) {
        const p = state.org.secrets.prod;
        if (p.paddleVendorId || p.paddleSecret) {
          return getters.paddleWithoutVersion;
        }
      }
      if (state.org.secrets.prod) {
        const t = state.org.secrets.test;
        if (t.paddleVendorId || t.paddleSecret) {
          return getters.paddleWithoutVersion;
        }
      }
    }
  }
  return null;
};

// eslint-disable-next-line arrow-body-style
export const braintreeLiveConnected = (state: IState): boolean => {
  return !!(state.org && state.org.secrets && !isEmpty(state.org.secrets.prod));
};
// eslint-disable-next-line arrow-body-style
export const braintreeTestConnected = (state: IState): boolean => {
  return !!(state.org && state.org.secrets && !isEmpty(state.org.secrets.prod));
};

export const stripeConnected = (state: IState, getters: any): boolean => !!state.org?.stripeAccountId;

export const paymentProvider = (state: IState, getters: any) => {
  if (state.org?.paymentProvider) {
    const p = state.org.paymentProvider;
    if (p === 'paddle') {
      return getters.paddleWithoutVersion;
    }
    return p;
  }
  return null;
};

// setting default to CLASSIC to support orgs without this field set
// although we might migrate them and explicitly set them to CLASSIC, too
export const paddleVersion = (state: IState): PaddleVersion => {
  if (state.org) {
    return state.org.paddleVersion || PADDLE_VERSION.CLASSIC;
  }
  return PADDLE_VERSION.CLASSIC;
};

// what to use for hiding and displaying UI components
export const provider = (state: IState, getters: any): string => getters.confirmedPaymentProvider || getters.paymentProvider || 'stripe';

export const paddleWithVersion = (state: IState, getters: any): string => {
  if (getters.paddleVersion === PADDLE_VERSION.BILLING) {
    return 'paddle_billing';
  }
  return 'paddle';
};

export const paddleWithoutVersion = (state: IState, getters: any): string => 'paddle';

// same as provider, but for Paddle it returns 'paddle' (for Classic) or 'paddle_billing' (for Billing)
// depending on version
export const providerWithVersion = (state: IState, getters: any): string => {
  if (getters.provider === 'paddle') {
    return getters.paddleWithVersion;
  }
  return getters.provider;
};

export const isPaddleClassic = (state: IState, getters: any): boolean => {
  const isPaddle = getters.provider === 'paddle';
  return isPaddle && getters.paddleVersion === PADDLE_VERSION.CLASSIC;
};

export const isPaddleBilling = (state: IState, getters: any): boolean => {
  const isPaddle = getters.provider === 'paddle';
  return isPaddle && getters.paddleVersion === PADDLE_VERSION.BILLING;
};

export const verifiedStripeUser = (state: IState, getters: any): boolean => getters.stripeConnected || getters.paymentProvider === 'stripe';

// not explicitly the inverse of above
// eslint-disable-next-line arrow-body-style
export const verifiedNotStripeUser = (state: IState, getters: any) => {
  return !getters.stripeConnected && getters.paymentProvider && getters.paymentProvider !== 'stripe';
};

export const slackConnection = (state: IState): ISlackConnection | null => {
  if (state.org && state.org.slack) {
    return state.org.slack;
  }
  return null;
};

export const payingCustomer = (state: IState, getters: any) => getters.userOrgSubscription && getters.userOrgSubscription?.plan?.amount;

export const appId = (state: IState) => {
  if (state.org) {
    return state.org.appId;
  }
  return undefined;
};

export const issues = (state: IState) => {
  if (state.issues) {
    return state.issues;
  }
  return [];
};

export const decoratedSessions = (state: IState, getters: any): IDecoratedSession[] => {
  if (state.sessions) {
    // wait until all of the relevant blueprints are loaded
    for (let i = 0; i < state.sessions.length; i++) {
      const s = state.sessions[i];
      if (!state.blueprints[s.blueprintId]) {
        return [];
      }
    }
    return state.sessions.map((s) => {
      const paused = !!(s.acceptedOffer && s.acceptedOffer.offerType === OfferType.PAUSE);
      const sessionBlueprint = state.blueprints[s.blueprintId];
      if (!(sessionBlueprint && sessionBlueprint.steps)) {
        return { ...s, surveyResponse: null, paused: false, offersPresented: [] };
      }
      let surveyResponse = null;
      const offersPresented: IOfferPresented[] = [];
      const pauseOffered =
        sessionBlueprint.steps[0].stepType === STEP_TYPE.OFFER && s.customer && s.customer.billingInterval === 'MONTH' && !s.customer.onTrial;
      if (pauseOffered) {
        offersPresented.push({
          accepted: paused,

          offer: sessionBlueprint.steps[0].offer,
        });
      }
      if (s.surveyChoiceId) {
        // make sure survey choice has been registered in the store
        if (getters.surveyChoices[s.blueprintId] && getters.surveyChoices[s.blueprintId][s.surveyChoiceId]) {
          const choice = getters.surveyChoices[s.blueprintId][s.surveyChoiceId];
          const surveyOffer = choice.offer;
          if (surveyOffer) {
            offersPresented.push({
              accepted: !!(s.acceptedOffer && s.acceptedOffer.guid === surveyOffer.guid),
              offer: surveyOffer,
            });
          }
          surveyResponse = choice.value;
        }
      }
      return {
        ...s,
        surveyResponse,
        paused,
        offersPresented,
      };
    });
  }
  return [];
};

export const orgHasPublishedBlueprint = (state: IState) => state.org && state.org.activeBlueprint;

export const orgCanUseAI = (state: IState, getters: any) => !!getters.provider;

export const orgShowEmailSettings = (state: IState, getters: any) => !!getters.orgSupportsManagedFlow;
export const orgShowDomainSettings = (state: IState, getters: any) => getters.provider === 'stripe';

export function validateBlueprintSteps(state: IState, getters: any) {
  return function (steps: BlueprintStep[]): BlueprintStep[] {
    let validatedSteps = [...steps];

    // disable pause steps if they need a pause coupon and it doesn't exist yet
    if (getters.missingPauseCoupon) {
      validatedSteps.forEach((step) => {
        if (step.stepType === STEP_TYPE.OFFER) {
          step.enabled = false;
        }
      });
    }

    // remove pause steps if org uses a provider that doesn't support pausing
    if (!getters.orgCanPause) {
      validatedSteps = validatedSteps.filter((step) => {
        if (isIOfferStep(step)) {
          return step?.offer?.offerType !== OfferType.PAUSE;
        }
        return true;
      });
    }

    // ensure offers have proper configs; just in case AI output lacks configs:
    validatedSteps = validatedSteps.map((step) => {
      if (isIOfferStep(step)) {
        switch (step.offer.offerType) {
          case OfferType.PAUSE:
            if (!step.offer.pauseConfig) {
              step.offer.pauseConfig = {
                maxPauseLength: 1,
                pauseInterval: PAUSE_INTERVAL.MONTH,
              };
            }
            break;
          case OfferType.DISCOUNT:
            if (!step.offer.discountConfig) {
              step.offer.discountConfig = {
                couponId: '',
                customAmount: 0, // for braintree
                customDuration: CUSTOM_DISCOUNT_DURATION.ONCE, // for braintree
              };
            }
            break;
          case OfferType.CONTACT:
            if (!step.offer.contactConfig) {
              step.offer.contactConfig = {
                email: '',
              };
            }
            break;
          case OfferType.REDIRECT:
            if (!step.offer.redirectConfig) {
              step.offer.redirectConfig = {
                redirectUrl: '',
                redirectLabel: '',
              };
            }
            break;
          case OfferType.PLAN_CHANGE:
            if (!step.offer.planChangeConfig) {
              step.offer.planChangeConfig = {
                options: [],
              };
            }
            break;
          case OfferType.TRIAL_EXTENSION:
            if (!step.offer.trialExtensionConfig) {
              step.offer.trialExtensionConfig = {
                trialExtensionDays: 7,
              };
            }
            break;
        }
      }
      return step;
    });
    return validatedSteps;
  };
}

export function blueprintOffers(state: IState, getters: any) {
  return function (blueprintId: string): Offer[] {
    const offers = [] as Offer[];
    const blueprint = state.blueprints[blueprintId];
    if (blueprintId && blueprint && blueprint.steps) {
      const offerSteps = blueprint.steps.filter((s) => s.stepType === STEP_TYPE.OFFER && s.enabled);
      if (offerSteps && offerSteps.length) {
        offers.push(...offerSteps.map((s) => s.offer));
      }
      const surveyStep = blueprint.steps.find((s) => s.stepType === STEP_TYPE.SURVEY && s.enabled);
      if (surveyStep && isISurveyStep(surveyStep)) {
        offers.push(...surveyStep.survey.choices.map((c) => c.offer).filter((o) => !!o));
      }
    }
    return offers;
  };
}

// abbreviated offers
export function offerToString(state: IState, getters: any) {
  return function (offer: Offer): string {
    switch (offer.offerType) {
      case OfferType.DISCOUNT:
        if (!offer.discountConfig.couponId) {
          return 'n/a';
        }
        const coupon = getters.couponMap[offer.discountConfig.couponId] as ICoupon;
        if (!coupon) {
          return 'n/a';
        }
        const normalCoupon = {
          id: coupon.id,
          couponAmount: coupon.amountOff || coupon.percentOff || 0,
          couponType: coupon.discountType,
          couponDuration: coupon.duration === 'once' ? 1 : coupon.months || 0,
          currency: coupon.currency,
        };
        return discountCouponToString(normalCoupon);
      case OfferType.PAUSE:
        return 'Pause';
      case OfferType.TRIAL_EXTENSION:
        return 'Trial Extension';
      case OfferType.PLAN_CHANGE:
        return 'Plan Change';
      case OfferType.CONTACT:
        return 'Contact';
      case OfferType.REDIRECT:
        return 'Redirect';
      default:
        return '';
    }
  };
}

export function offerOutcomeToString(state: IState, getters: any) {
  return function (o: IOfferOutcomeCount): string {
    if (o.offerType === OfferType.CONTACT) {
      return 'Customer Support';
    }
    if (o.offerType === OfferType.PAUSE) {
      return 'Pause';
    }
    if (o.offerType === OfferType.REDIRECT) {
      return 'Redirect';
    }
    if (o.offerType === OfferType.TRIAL_EXTENSION) {
      return 'Trial Extension';
    }
    if (o.offerType === OfferType.PLAN_CHANGE) {
      return 'Change Plan';
    }
    if (o.offerType === OfferType.DISCOUNT) {
      if (o.couponId) {
        return getters.couponIdToString(o.couponId);
      }
      return 'Discount';
    }
    return '';
  };
}

export function couponIdToString(state: IState, getters: any) {
  return function (couponId: string): string {
    if (getters.provider === 'paddle') {
      const coupon = paddleCouponIdToCoupon(couponId);
      if (!coupon) {
        return couponId;
      }
      return discountCouponToString(coupon);
    }

    const coupon = getters.couponMap[couponId] as ICoupon;
    if (!coupon) {
      return couponId;
    }
    const normalCoupon = {
      id: coupon.id,
      couponAmount: coupon.amountOff || coupon.percentOff || 0,
      couponType: coupon.discountType,
      couponDuration: coupon.duration === 'once' ? 1 : coupon.months || 0,
      currency: coupon.currency,
    };
    return discountCouponToString(normalCoupon);
  };
}

// more verbose
export function blueprintOfferToString(state: IState, getters: any) {
  return function (offer: Offer): string {
    switch (offer.offerType) {
      case OfferType.DISCOUNT:
        if (!offer.discountConfig.couponId) {
          return 'Coupon';
        }
        const coupon = getters.couponMap[offer.discountConfig.couponId] as ICoupon;
        if (!coupon) {
          return `Coupon ${offer.discountConfig.couponId}`;
        }
        const normalCoupon = {
          id: coupon.id,
          couponAmount: coupon.amountOff || coupon.percentOff || 0,
          couponType: coupon.discountType,
          couponDuration: coupon.duration === 'once' ? 1 : coupon.months || 0,
          currency: coupon.currency,
        };
        const couponString = discountCouponToString(normalCoupon);
        return `${couponString}`;
      case OfferType.PAUSE:
        if (offer.pauseConfig) {
          return `subscription pause up to ${offer.pauseConfig.maxPauseLength} ${offer.pauseConfig.maxPauseLength === 1 ? 'month' : 'months'}`;
        }
        return 'subscription pause';
      case OfferType.TRIAL_EXTENSION:
        return 'trial extension';
      case OfferType.PLAN_CHANGE:
        return 'plan change';
      case OfferType.CONTACT:
        return 'contact us';
      case OfferType.REDIRECT:
        return `redirect ("${offer.redirectConfig.redirectLabel}")`;

      default:
        return '';
    }
  };
}

export const brandColor = (state: IState) => {
  return state.org?.branding?.primaryColor || '#3c5ccf';
};

export const brandColorMiddle = (state: IState, getters: any) => {
  return `${getters.brandColor}55`;
};

export const brandColorLight = (state: IState, getters: any) => {
  return `${getters.brandColor}11`;
};

export const dataApiEnabled = (state: IState, getters: any) => {
  return state.org?.dataApiEnabled || getters.apiUrl || false;
};

export const customerHealthEnabled = (state: IState, getters: any) => {
  return state.org?.customerHealthEnabled;
};

export const apiUrl = (state: IState) => {
  return state.org?.apiUrl || '';
};

export const stripePriceDescription = (state: IState) => (priceId: string, includeId: boolean, useNickname?: boolean) => {
  let description = '';
  if (state.stripeData.prices) {
    const priceData = state.stripeData.prices.find((p) => p.id === priceId);
    if (priceData) {
      if (priceData.nickname && useNickname) {
        return priceData.nickname;
      }
    }

    if (priceData && priceData.currency && priceData.unit_amount) {
      const amount = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: priceData.currency,
      }).format(priceData.unit_amount / 100);
      let recurring = '';
      if (priceData.recurring) {
        const intervalCount = priceData.recurring.interval_count;
        const interval = priceData.recurring.interval;
        recurring = `/${interval}`;
        if (intervalCount !== 1) {
          recurring = `/${intervalCount} ${interval}s`;
        }
      }
      description = `${priceData.product.name} for ${amount}${recurring}`;
    } else if (priceData && priceData.product && priceData.product.name) {
      description = priceData.product.name;
      if (priceData.unit_amount === 0) {
        description += ' (Free)';
      }
    }
  }
  if (includeId) {
    if (description.length) {
      return `${priceId} (${description})`;
    }
    return priceId;
  }
  return description || priceId;
};

export const stripeProductName = (state: IState) => (productId: string) => {
  let description = productId;
  if (state.stripeData.products) {
    const productData = state.stripeData.products.find((p) => p.id === productId);
    if (productData && productData.name) {
      description = productData.name;
    }
  }
  return description;
};

export const stripePriceString = (state: IState) => (priceId: string) => {
  if (state.stripeData.prices) {
    const priceData = state.stripeData.prices.find((p) => p.id === priceId);
    if (priceData && priceData.unit_amount && priceData.recurring !== null) {
      let amount = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: priceData.currency,
        currencyDisplay: 'narrowSymbol',
      }).format(priceData.unit_amount / 100);
      // trim off currency trailing decimal zeros
      amount = amount.replace(/\.00$/, '');
      let recurring = '';
      if (priceData.recurring) {
        const intervalCount = priceData.recurring.interval_count;
        const interval = priceData.recurring.interval;
        recurring = `/${interval}`;
        if (intervalCount !== 1) {
          recurring = `/${intervalCount} ${interval}s`;
        }
      }
      return `<span class="inline-block">${amount}</span><span class="inline-block mx-1 text-sm align-sub">${recurring}</span>`;
    }
  }
  return null;
};

export function segmentIdToString(state: IState) {
  return function (segmentId: string): string {
    if (segmentId === 'null') {
      return 'Primary Flow';
    }
    const segment = state.segments[segmentId];
    if (segment && segment.name) {
      return segment.name;
    }
    return segmentId;
  };
}

export function campaignBlueprintIdToString(state: IState) {
  return function (campaignBlueprintId: string): string {
    const cb = state.campaignBlueprints[campaignBlueprintId];
    if (!cb) {
      return 'n/a';
    }
    if (cb && cb.audience) {
      return cb.audience.name || 'Segmented Campaign';
    }
    return 'Primary Campaign';
  };
}

// audience preivew for teaser card
export const segmentFilterToString = (state: IState, getters: any) => (filter: ISegmentFilter) => {
  const { attribute, operand, value } = filter || ({} as ISegmentFilter);
  if (attribute && operand && value) {
    const valueToString = (filterValue: SegmentFilterValue, isDate: boolean) => {
      if (!filterValue) {
        return '';
      }
      if (isDate) {
        return dateFormat(new Date(filterValue));
      }
      if (attribute === SEGMENT_ATTRIBUTE.PLAN_ID) {
        return getters.stripePriceDescription(filterValue, false);
      }
      if (attribute === SEGMENT_ATTRIBUTE.PRODUCT_ID) {
        return getters.stripeProductName(filterValue);
      }
      return filterValue.toString();
    };

    let isDate = SEGMENT_ATTRIBUTE_TYPE[attribute as SegmentAttribute] === CUSTOM_ATTRIBUTE_TYPE.DATE;
    let attributeString = segmentAttributeLabels[attribute];
    if (!attributeString) {
      // look for custom attribute match
      const customAttribute = state.org?.customAttributes?.find((a) => a.name === attribute);
      if (customAttribute) {
        attributeString = customAttribute.label;
        if (customAttribute.type === CUSTOM_ATTRIBUTE_TYPE.DATE) {
          isDate = true;
        }
      }
    }
    // If date, use date-specific operand labels:
    const operandString = isDate ? segmentDateOperandLabels[operand] : segmentOperandLabels[operand];
    let valueString: string;
    if (Array.isArray(value)) {
      const joiner = operand === SEGMENT_OPERAND.BETWEEN ? ' and ' : ' or ';
      valueString = value.map((v) => valueToString(v, isDate)).join(joiner);
    } else {
      valueString = valueToString(value, isDate);
    }
    return `${attributeString} ${operandString} ${valueString}`;
  }
  return '';
};

export const cancelFlowOnboardState = (state: IState) => {
  const sessions = state.recentSessions;
  const testSessions = state.testSessions;
  if (!(state && state.org)) {
    return null;
  }
  const { blueprints } = state.org;
  if (!(blueprints && blueprints.length)) {
    return 'NO_FLOW';
  }
  if (!sessions.length) {
    if (testSessions.length) {
      return 'NO_LIVE_SESSIONS';
    }
    return 'NO_SESSIONS';
  }
  return 'LIVE';
};

export const evalBoolean = (state: IState, getters: any) => {
  return (arg?: ValueOrGetter<boolean>) => {
    if (typeof arg === 'undefined') {
      return false;
    }
    if (typeof arg === 'boolean') {
      return arg;
    }
    return arg(state, getters);
  };
};

export const evalString = (state: IState, getters: any) => {
  return (arg?: ValueOrGetter<string>) => {
    if (typeof arg === 'undefined') {
      return '';
    }
    if (typeof arg === 'string') {
      return arg;
    }
    return arg(state, getters);
  };
};

export const paymentProcessorStepCompleted = (state: IState) => {
  if (!state.org) {
    return false;
  }

  const { paymentProvider } = state.org;

  if (state.org.provider?.type) {
    return true;
  }

  return (state.org as any)[`${paymentProvider}Authorized`] === true;
};

export const companyName = (state: IState, getters: any) => {
  return getters.confidential ? 'Acme Inc.' : state?.org?.name || 'Your company';
};

export const hasAnyProductSubscription = (state: IState, getters: any) => {
  return [
    getters.hasReactivationSubscription,
    getters.hasTrialReactivationSubscription,
    getters.hasDunningSubscription,
    getters.hasTrialDunningSubscription,
    getters.hasCancelFlowSubscription,
    getters.hasTrialCancelFlowSubscription,
  ].some(Boolean);
};

export const hasAnyTrialSubscription = (state: IState, getters: any) => {
  return [getters.hasTrialReactivationSubscription, getters.hasTrialDunningSubscription, getters.hasTrialCancelFlowSubscription].some(Boolean);
};

export const hasAnyNonTrialSubscription = (state: IState, getters: any) => {
  return [getters.hasNonTrialCancelFlowSubscription, getters.hasNonTrialDunningSubscription, getters.hasNonTrialCancelFlowSubscription].some(Boolean);
};

export const hasNonMonthlySubscription = (state: IState) => {
  return hasNonMonthlySubscriptionHelper(state);
};
