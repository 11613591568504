import { ProviderLabels } from './provider-labels';

export const labels: ProviderLabels = {
  provider: 'NXT Wash',
  environment: {
    live: 'Live',
    test: 'Test',
  },
  coupon: {
    singular: 'Coupon',
    plural: 'Coupons',
    id: 'Code',
  },
  dashboard: {
    login: 'https://www.nxtwash.com',
  },
};
