import { PADDLE_VERSION } from '@/store/shared';
import { ICoupon, IOrg, IState } from '../../types';
import { SET_PROVIDER_FEATURES } from '../../mutation-types';
import { FETCH_PROVIDER_FEATURES } from '../../action-types';
import { ProviderFeatures } from './features/legacy/provider-features';
import { Provider, ProviderName } from './types';
import { providerFeatures } from './features/legacy';
import { providerLabels } from './labels';

const getOrgProviderType = (org: IOrg): ProviderName => {
  switch (org.paymentProvider?.toLowerCase()) {
    case 'stripe':
      return Provider.Stripe;
    case 'braintree':
      return Provider.Braintree;
    case 'chargebee':
      return org.chargebeeVersion === '1' ? Provider.ChargebeeV1 : Provider.ChargebeeV2;
    case 'paddle':
      return org.paddleVersion === PADDLE_VERSION.CLASSIC ? Provider.PaddleClassic : Provider.PaddleBilling;
    case 'maxio':
      return Provider.Maxio;
    default:
      switch (org.provider?.type) {
        case 'nxt-wash':
          return Provider.NxtWash;
        default:
          return Provider.Other;
      }
  }
};

const providerModule = {
  state: {
    providerFeatures: providerFeatures[Provider.Stripe],
  },
  getters: {
    requiresPauseCoupon: (state: IState) => state.providerFeatures.actions.pause.requiredCoupon !== undefined,
    hasPauseCoupon: (state: IState, getters: any) =>
      getters.coupons.find((c: ICoupon) => c.id === state.providerFeatures.actions.pause.requiredCoupon) !== undefined,
    missingPauseCoupon: (state: IState, getters: any): boolean => !getters.hasPauseCoupon && getters.requiresPauseCoupon,
    orgSupportsManagedFlow: (state: IState) => state.providerFeatures.controllers.customers.findByEmail,
    orgCanPause: (state: IState) => state.providerFeatures.actions.pause.enabled,
    orgSupportsCoupons: (state: IState) => state.providerFeatures.controllers.coupons.enabled,
    orgCanCreateCoupons: (state: IState) => state.providerFeatures.controllers.coupons.create,

    providerLabels(state: IState) {
      // TODO: figure out if we want to do getter or state and adjust here
      if (!state.org) {
        return providerLabels[Provider.Stripe];
      }
      const providerType = getOrgProviderType(state.org);
      return providerLabels[providerType];
    },
  },
  mutations: {
    [SET_PROVIDER_FEATURES](state: IState, features: ProviderFeatures) {
      state.providerFeatures = features;
    },
  },
  actions: {
    [FETCH_PROVIDER_FEATURES]({ commit, state }: any) {
      if (state.org) {
        // TODO: replace this with API call to get provider features
        const providerType = getOrgProviderType(state.org);
        const features = providerFeatures[providerType];
        commit(SET_PROVIDER_FEATURES, features);
      }
    },
  },
};

export default providerModule;
