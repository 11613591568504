import { IState } from '../types';
import { ProductName } from '@/constants/ProductName';
import { hasNonTrialProductSubscription, hasProductSubscription, hasTrialSubscription } from '@/helpers/subscriptions';
import { getProductByName } from '@/helpers/products';

const cancelFlowsModule = {
  state: {},
  actions: {},
  mutations: {},
  getters: {
    hasCancelFlowSubscription(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return hasProductSubscription(state, ProductName.cancelFlow);
    },
    hasTrialCancelFlowSubscription(state: IState) {
      return hasTrialSubscription(state, ProductName.cancelFlow);
    },
    hasNonTrialCancelFlowSubscription(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return hasNonTrialProductSubscription(state, ProductName.cancelFlow);
    },
    cancelFlowsProduct(state: IState) {
      return getProductByName(state, ProductName.cancelFlow);
    },
    createAndPushFirstCancelFlowStepCompleted(state: IState) {
      if (!state.org) {
        return false;
      }

      return state.org.blueprints && state.org.blueprints.length > 0;
    },
    allRequiredCancelFlowStepsCompleted(state: IState, getters: any) {
      return [getters.createAndPushFirstCancelFlowStepCompleted, getters.paymentProcessorStepCompleted].every(Boolean);
    },
    isCancelFlowsProductLive(state: IState, getters: any) {
      // return true;
      // for now, it is our assumption that parent orgs will always be "live" with cancel flows
      if (getters.hasLegacyPricing || state.org?.isParent) {
        return true;
      }
      return getters.cancelFlowsProduct?.isLive || false;
    },
    isCancelFlowsProductAvailable(state: IState, getters: any) {
      return getters.cancelFlowsProduct?.isAvailable || false;
    },
    isCancelFlowsProductTrial(state: IState, getters: any) {
      return getters.cancelFlowsProduct?.isTrial || false;
    },
  },
};

export default cancelFlowsModule;
